import React from "react";
import AboutUploader from "../component/aboutUploader";

const About = () => {
  return (
    <div className="home ">
      <AboutUploader />
    </div>
  );
};

export default About;
